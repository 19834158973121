import { Layout, NotFound, SEO } from '../components/common'

import React from 'react'

export default () => (
  <Layout>
    <SEO title="404: Not found" location="/404" />
    <NotFound />
  </Layout>
)
